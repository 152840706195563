import React from "react"

import Form from "../../../components/form/form"
import FormNumericInput from "../../../components/form/form-numeric-input"
import LineChart, {
  makeChartConfiguration,
} from "../../../components/line-chart"
import Page from "../../../components/page"
import { OneColumnGrid, TwoColumnsGrid } from "../../../components/containers"
import { SectionLarge } from "../../../components/elements"
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "../../../helpers/functions"

export default class InflationPage extends React.Component {
  state = {
    inputs: {
      capital: 10000,
      growthRate: 0.1,
      discountRate: 0.05,
      numberOfYears: 10,
    },
    outputs: {
      discountedCashFlowDataset: [],
      nominalCashFlowDataset: [],
      finalValue: 0,
    },
  }

  _compute = () => {
    const { inputs, outputs } = this.state
    const { capital, growthRate: g, discountRate: r, numberOfYears } = inputs
    const discountedCashFlowDataset = [capital]
    const nominalCashFlowDataset = [capital]
    let year = 1,
      finalValue = 0
    while (year <= numberOfYears) {
      const value = capital * Math.pow((1 + g) / (1 + r), year)
      nominalCashFlowDataset.push(capital * Math.pow(1 + g, year))
      discountedCashFlowDataset.push(value)
      finalValue += value
      year += 1
    }
    this.setState({
      ...this.state,
      outputs: {
        ...outputs,
        discountedCashFlowDataset,
        nominalCashFlowDataset,
        finalValue,
      },
    })
  }

  _handleUpdate = event => {
    event.preventDefault()
    const { name, value } = event.target
    let newState = { ...this.state }
    newState.inputs[name] = parseFloat(value)
    this.setState(newState)
    this._compute()
  }

  _handleSubmit = event => {
    event.preventDefault()
    this._compute()
  }

  componentDidMount() {
    this._compute()
  }

  render() {
    const { inputs, outputs } = this.state
    const { capital, growthRate, discountRate, numberOfYears } = inputs
    const {
      discountedCashFlowDataset,
      nominalCashFlowDataset,
      finalValue,
    } = outputs
    const chartData = {
      labels: discountedCashFlowDataset.map((_, i) => `${i}`),
      datasets: [
        {
          ...makeChartConfiguration({
            label: "Flusso di Cassa Scontato",
          }),
          data: discountedCashFlowDataset,
        },
        {
          ...makeChartConfiguration({
            label: "Flusso di Cassa Nominale",
            color: "rgb(86, 0, 240)",
          }),
          data: nominalCashFlowDataset,
        },
      ],
    }
    const title = "Calcolatore per il Flusso di Cassa Attualizzato"
    return (
      <Page title={title}>
        <SectionLarge>
          <h1 className="heading">{title}</h1>
          <h2 className="subheading">
            Calcola il valore intrinseco di un'azienda in base agli incassi
            futuri, la loro crescita, e un tasso di sconto.
          </h2>
          <OneColumnGrid>
            <Form onSubmit={this._handleSubmit}>
              <TwoColumnsGrid>
                <FormNumericInput
                  label="Flusso di Cassa Iniziale"
                  format="currency"
                  name="capital"
                  value={capital}
                  onChange={this._handleUpdate}
                />
                <FormNumericInput
                  label="Numero di anni"
                  name="numberOfYears"
                  onChange={this._handleUpdate}
                  value={numberOfYears}
                  maxValue={100}
                />
                <FormNumericInput
                  label="Tasso di crescita"
                  format="percentage"
                  name="growthRate"
                  onChange={this._handleUpdate}
                  value={growthRate}
                />
                <FormNumericInput
                  label="Tasso di sconto"
                  format="percentage"
                  name="discountRate"
                  onChange={this._handleUpdate}
                  value={discountRate}
                />
              </TwoColumnsGrid>
            </Form>
            <p className="text-center">
              Il valore intrinseco di un Flusso di Cassa di{" "}
              {formatCurrency(capital)} sarà pari a{" "}
              <strong>{formatCurrency(finalValue)}</strong> dopo{" "}
              {formatNumber(numberOfYears)} anni con un tasso di crescita del{" "}
              {formatPercentage(growthRate)} e un tasso di sconto del{" "}
              {formatPercentage(discountRate)}.
            </p>
            <LineChart data={chartData} />
          </OneColumnGrid>
        </SectionLarge>
      </Page>
    )
  }
}
